import { HttpClient } from '@angular/common/http';
import { mergeDeep } from '@ngx-translate/core';
import { of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
class MultiTranslateHttpLoader {
  _handler;
  _resourcesPrefix;
  constructor(_handler, _resourcesPrefix) {
    this._handler = _handler;
    this._resourcesPrefix = _resourcesPrefix;
  }
  getTranslation(lang) {
    const requests = this._resourcesPrefix.map(resource => {
      let path;
      if (typeof resource === 'string') path = `${resource}${lang}.json`;else path = `${resource.prefix}${lang}${resource.suffix ?? '.json'}`;
      return new HttpClient(this._handler).get(path).pipe(catchError(res => {
        if (typeof resource !== 'string' && !resource.optional) {
          console.group();
          console.error('Something went wrong for the following translation file:', path);
          console.error(res);
          console.groupEnd();
        }
        return of({});
      }));
    });
    return forkJoin(requests).pipe(map(response => response.reduce((acc, curr) => mergeDeep(acc, curr), {})));
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { MultiTranslateHttpLoader };
