import { inject, Injectable } from '@angular/core';
import { API_ROUTES } from '@constants/api-routes';
import { FeatureFlagService } from '@directives/conditional-rendering/feature-flag/feature-flag.service';
import { applicationKeys } from '@models/applications.model';
import {
  MeAppConfigModifyRequest,
  MeAppConfigModifyResponse,
  MeGetResponse,
  MePermissionsGetResponse,
  ModifyMePatchRequest,
  ModifyMePatchResponse,
} from '@models/me.model';
import { RefreshSubjectEntity } from '@services/data-access/_core/trigger-entities.service';
import { map, Observable, switchMap, tap } from 'rxjs';
import { CoreApiService } from '../_core/core-api.service';

@Injectable({
  providedIn: 'root',
})
export class MeApiService extends CoreApiService {
  private featureFlagService = inject(FeatureFlagService);
  protected entityName: RefreshSubjectEntity = RefreshSubjectEntity.Me;

  getMe(): Observable<MeGetResponse> {
    return this.getRefreshSubject().pipe(
      switchMap(() =>
        this.http.get<MeGetResponse>(API_ROUTES.users.me.root).pipe(
          map((res) => {
            // Attach default limits
            if (!res.limits.apps)
              res.limits.apps = [
                ...applicationKeys.filter((appKey) => {
                  return (
                    !this.featureFlagService.isRegisteredFeatureFlag(appKey) ||
                    this.featureFlagService.isEnabled(appKey)
                  );
                }),
              ];
            return res;
          }),
        ),
      ),
    );
  }

  getMePermissions(): Observable<MePermissionsGetResponse> {
    return this.getRefreshSubject().pipe(
      switchMap(() =>
        this.http.get<MePermissionsGetResponse>(
          API_ROUTES.users.me.permissions,
        ),
      ),
    );
  }

  modifyMeAppConfig(
    body: MeAppConfigModifyRequest,
  ): Observable<MeAppConfigModifyResponse> {
    return this.http
      .put<MeAppConfigModifyResponse>(API_ROUTES.users.me.config, body)
      .pipe(tap(() => this.triggerRefresh()));
  }

  patchModifyMe(body: ModifyMePatchRequest): Observable<ModifyMePatchResponse> {
    return this.http
      .patch<ModifyMePatchResponse>(API_ROUTES.users.me.root, body)
      .pipe(
        tap(() =>
          this.snackbarService.add({
            color: 'success',
            message: 'EDITING_COMPLETE',
          }),
        ),
        tap(() => this.triggerRefresh()),
      );
  }
}
