import {
  HttpBackend,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ModuleWithProviders,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { API_ROUTES } from '@constants/api-routes';
import { environment } from '@environments/environment';
import { authInterceptor } from '@interceptors/auth.interceptor';
import { handleErrorsInterceptor } from '@interceptors/handle-errors.interceptor';
import { loggerInterceptor } from '@interceptors/logger.interceptor';
import { serverDelayNotificationInterceptor } from '@interceptors/server-delay-notification.interceptor';
import { serverErrorNotificationInterceptor } from '@interceptors/server-error-notification.interceptor';
import { LanguageFlags } from '@models/languages.model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import Aura from '@primeng/themes/aura';
import { InitializationService } from '@services/initialization.service';
import { TitleStrategyService } from '@services/title-strategy.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { providePrimeNG } from 'primeng/config';
import { APP_ROUTES } from './app.routes';

// AoT requires an exported function for factories
export const HttpLoaderFactory = (
  httpHandler: HttpBackend,
): MultiTranslateHttpLoader => {
  return new MultiTranslateHttpLoader(httpHandler, [
    {
      prefix: `${environment.server_url}${environment.api_version}${API_ROUTES.static.translations}/`,
      suffix: '.json',
      optional: true,
    },
    { prefix: './i18n/', suffix: '.json' },
  ]);
};

export const DefaultTranslateModule =
  (): ModuleWithProviders<TranslateModule> =>
    TranslateModule.forRoot({
      defaultLanguage: LanguageFlags.es,
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    });

export const DefaultTranslateModuleTest =
  (): ModuleWithProviders<TranslateModule> =>
    TranslateModule.forRoot({
      defaultLanguage: LanguageFlags.es,
      useDefaultLang: true,
    });

export const appConfig: ApplicationConfig = {
  providers: [
    provideServiceWorker('custom-ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    { provide: TitleStrategy, useClass: TitleStrategyService },
    provideRouter(
      APP_ROUTES,

      withComponentInputBinding(),
      //
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        urlUpdateStrategy: 'eager',
      }),
    ),

    providePrimeNG({
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: false,
          cssLayer: {
            name: 'primeng',
            order: 'primeng, reset',
          },
        },
      },
    }),

    provideHttpClient(
      withInterceptors([
        authInterceptor,
        loggerInterceptor,
        handleErrorsInterceptor,
        serverErrorNotificationInterceptor,
        serverDelayNotificationInterceptor,
      ]),
    ),
    importProvidersFrom(DefaultTranslateModule()),
    // TODO: find the way to remove it -> with PrimeNG, it is impossible to DELETE
    provideAnimations(),
    provideAppInitializer(() => inject(InitializationService).init()),
  ],
};
