import { computed, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, from, map, Observable, of } from 'rxjs';

export enum MessageTypeSw {
  ClearCache = 'clear_cache',
  ClearImagesCache = 'clear_images_cache',
  CacheCleared = 'cache_cleared',
  SetOfflineMode = 'set_offline_mode',
  SetOfflineNetwork = 'set_offline_network',
}

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  private readonly isServiceWorkerReady = toSignal(
    from(navigator.serviceWorker?.ready).pipe(
      map((registration) => !!registration.active),
      catchError(() => of(false)),
    ),
  );
  private readonly _isServiceWorkerControllerReady = signal(false);

  isServiceWorkerControllerReady = computed(
    () => this.isServiceWorkerReady() && this._isServiceWorkerControllerReady(),
  );

  constructor() {
    if (navigator.serviceWorker?.controller) {
      this._isServiceWorkerControllerReady.set(true);
    }
    this.onControllerChange();
  }

  clearCache(): Observable<void> {
    return new Observable((observer) => {
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event): void => {
        if (event?.data?.type === MessageTypeSw.CacheCleared) {
          // * Emits a value when the cache will be cleared
          observer.next();
          observer.complete();
          return;
        }

        observer.error(new Error('THE_CACHE_COULD_NOT_BE_CLEARED'));
      };

      // ! Make sure to pass port2 to postMessage
      const isMessageSent = this.postMessage(MessageTypeSw.ClearCache, {}, [
        messageChannel.port2,
      ]);

      if (!isMessageSent) observer.error(new Error('SW_NOT_AVAILABLE'));
    });
  }

  postMessage(
    type: MessageTypeSw,
    message: object = {},
    transfer: Transferable[] = [],
  ): boolean {
    const controller = navigator.serviceWorker?.controller;
    if (!controller) {
      console.error('SW_NOT_AVAILABLE');
      return false;
    }

    controller.postMessage({ ...message, type }, transfer);
    return true;
  }

  private onControllerChange(): void {
    if (!navigator.serviceWorker) return;

    navigator.serviceWorker.oncontrollerchange = (): void =>
      this._isServiceWorkerControllerReady.set(true);
  }
}
