import { DOCUMENT } from '@angular/common';
import { effect, inject, Injectable } from '@angular/core';
import { ColorSchemeEnum } from '@enums/color-scheme.enum';
import { TranslateService } from '@ngx-translate/core';
import { MeConfigStateService } from '@state-management/me-config-state';
import { SystemStateService } from '@state-management/system-state';
import { take, tap } from 'rxjs';
import { PrimeTranslateService } from './prime-translate.service';
import { MessageTypeSw, ServiceWorkerService } from './service-worker.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private _document = inject(DOCUMENT);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly translateService = inject(TranslateService);
  private readonly primeTranslateService = inject(PrimeTranslateService);
  private readonly serviceWorkerService = inject(ServiceWorkerService);

  constructor() {
    this.setThemePreferencesEffect();
    this.setLanguagePreferencesEffect();
    this.sendIsOfflineStateToSW();
  }

  init(): void {
    //! This is a mock function created since import of systemService is required for the initialization of the service.
  }

  private setThemePreferencesEffect(): void {
    effect(() => {
      const isDarkTheme =
        this.meConfigStateService.getValue('theme')() === ColorSchemeEnum.Dark;

      return isDarkTheme
        ? this._document.documentElement.setAttribute('data-theme', 'dark')
        : this._document.documentElement.removeAttribute('data-theme');
    });
  }

  private setLanguagePreferencesEffect(): void {
    effect(() => {
      const language = this.meConfigStateService.getValue('language')();

      this._document.documentElement.lang = language;
      this.translateService
        .use(language)
        .pipe(
          take(1),
          tap(() => this.primeTranslateService.setTranslation()),
        )
        .subscribe();
    });
  }

  private sendIsOfflineStateToSW(): void {
    effect(() => {
      const isOffline = this.systemStateService.getValue('isOffline')();
      const isActive =
        this.serviceWorkerService.isServiceWorkerControllerReady();

      if (!isActive) return;

      this.serviceWorkerService.postMessage(MessageTypeSw.SetOfflineMode, {
        isOffline,
      });
    });

    effect(() => {
      const isNetworkOffline =
        this.systemStateService.getValue('isNetworkOffline')();
      const isActive =
        this.serviceWorkerService.isServiceWorkerControllerReady();

      if (!isActive) return;

      this.serviceWorkerService.postMessage(MessageTypeSw.SetOfflineNetwork, {
        isOffline: isNetworkOffline,
      });
    });
  }
}
