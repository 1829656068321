import { effect, inject, Injectable, Injector, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AllIndexedDbStores } from '@models/indexed-db-stores.model';
import { SystemStateService } from '@state-management/system-state';
import {
  catchError,
  combineLatest,
  from,
  interval,
  map,
  of,
  switchMap,
} from 'rxjs';
import { CoreIndexedDbService } from '../_core/core-indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineIndexedDbService extends CoreIndexedDbService {
  private readonly isOffline = inject(SystemStateService).getValue('isOffline');
  private readonly injector = inject(Injector);

  private readonly isOffline$ = toObservable(this.isOffline);

  protected storeName: AllIndexedDbStores = 'offline';

  init(): void {
    effect(
      () => {
        const isOffline = this.isOffline();
        this.setIsOffline(isOffline);
      },
      { injector: this.injector },
    );
  }

  existsDataToSync(): Signal<boolean> {
    return toSignal(
      combineLatest([
        from(this.getDBConnection()),
        this.isOffline$,
        interval(1000 * 3),
      ]).pipe(
        switchMap(([db, isOffline]) => {
          if (isOffline) return of(false);

          return from(
            Promise.all(
              this.storesName.map((storeName) =>
                this.getAllRecords(db, storeName),
              ),
            ),
          ).pipe(
            map((results) => results.some((records) => !!records.length)),
            catchError(() => from([false])),
          );
        }),
        catchError(() => from([false])),
      ),
      { initialValue: false },
    );
  }

  async setIsOffline(value: boolean): Promise<number> {
    const db = await this.getDBConnection();
    return this._setIsOffline(db, value);
  }

  private _setIsOffline(db: IDBDatabase, value: boolean): Promise<number> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(this.storeName, 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.put(value, 'isOfflineMode');

      request.onsuccess = (): void => {
        resolve(request.result as number);
      };

      request.onerror = (): void => {
        reject(request.error);
      };
    });
  }
}
