import { Injectable, inject } from '@angular/core';
import { DAYS_MIN, MONTHS_FULL } from '@constants/date';
import { PrimeNG } from 'primeng/config';
import { NaxTranslateService } from './translate.service';

@Injectable({
  providedIn: 'root',
})
export class PrimeTranslateService {
  private readonly translateService = inject(NaxTranslateService);
  private readonly primeNG = inject(PrimeNG);

  setTranslation(): void {
    this.primeNG.setTranslation({
      today: this.translateService.instant('TODAY'),
      clear: this.translateService.instant('CLEAR'),
      monthNames: Object.values(
        this.translateService.instant(MONTHS_FULL) as unknown as Record<
          string,
          string
        >,
      ),
      dayNamesMin: Object.values(
        this.translateService.instant(DAYS_MIN) as unknown as Record<
          string,
          string
        >,
      ),
    });
  }
}
